import * as React from 'react';
import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import DynPlayerLogo from './dynplayer.png';
import Copyright from './components/Dashboard/Copyright';
import { Divider } from '@mui/material';
import Paper from '@mui/material/Paper';


function CheckToken(token)
{
    return false;
}

function Login()
{
    const [tenant, setTenant] = useState('dierresystems');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const defaultTheme = createTheme({ palette: { mode: 'dark' } });

    const handleSubmit = async (event) =>
    {
        event.preventDefault();
        setErrorMessage(null);

        try
        {
            const body = JSON.stringify({ username, password, tenant });
            const response = await fetch('https://server.dynplayer.it/public/index.php/dynPlayerServer/jwtLogin', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body,
            });

            if (!response.ok)
            {
                throw new Error(`Errore API: ${response.status}`);
            }

            const data = await response.json();
            console.log('Accesso effettuato:', data);

            // Gestisci l'accesso con successo (es: memorizza token, reindirizza)

        } catch (error)
        {
            console.error('Errore accesso:', error);
            setErrorMessage(error.message);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Paper
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: "50px"
                    }}
                >
                    <Box sx={{
                        paddingBottom: '30px'
                    }}>
                        <img alt='dynPlayer Logo' width="280" src={DynPlayerLogo}></img>
                    </Box>
                    <Avatar sx={{ m: 1, bgcolor: '#fe6500' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Desk Login
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username Address"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            onChange={(e) => setUsername(e.target.value)}
                            />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        {errorMessage && (
                            <Typography variant="body2" color="error">
                                {errorMessage}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSubmit}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                {/* <Divider sx={{ mt: '30px' }} /> */}
                <Copyright sx={{ pt: 2 }} />
            </Container>
        </ThemeProvider>
    );
}

export { CheckToken, Login }