import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Dashboard from './App';
import {Login,CheckToken} from './Login';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

localStorage.setItem('authToken','tokenTest');
const token = localStorage.getItem('authToken');
if (token && CheckToken(token))
{
  root.render(
    <React.StrictMode>
      <Dashboard />
    </React.StrictMode>
  );
}
else
{
  root.render(
    <React.StrictMode>
      <Login />
    </React.StrictMode>
  );

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log());
