import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
/*--- Icons ---*/
import HomeIcon from '@mui/icons-material/Home';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ImageIcon from '@mui/icons-material/Image';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import { Link } from 'react-router-dom';

export const DynMenuItems = (
    <React.Fragment>
        <Link to="/componente1" style={{textDecoration: 'none'}}>
            <ListItemButton>
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText className='DynMenuText' primary="Home" />
            </ListItemButton>
        </Link>
        <Link to="/componente2" style={{textDecoration: 'none'}}>
            <ListItemButton>
                <ListItemIcon>
                    <OndemandVideoIcon />
                </ListItemIcon>
                <ListItemText className='DynMenuText' primary="Players" />
            </ListItemButton>
        </Link>
        <ListItemButton>
            <ListItemIcon>
                <ImageIcon />
            </ListItemIcon>
            <ListItemText primary="Contenuti" />
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <ManageHistoryIcon />
            </ListItemIcon>
            <ListItemText primary="Pianificazioni" />
        </ListItemButton>
        <ListItemButton>
            <ListItemIcon>
                <DevicesOtherIcon />
            </ListItemIcon>
            <ListItemText primary="Canali" />
        </ListItemButton>
    </React.Fragment>
);



